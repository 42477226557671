
html {
    font-family: sans-serif;
    font-size: 14px;
}

table {
    /*border: 1px solid lightgrey;*/
}


tfoot {
    color: grey;
}


tfoot th {
    font-weight: normal;
}

th {
    margin: 0;
    padding: 0;
}
