.tutorialCardTip.left-top {
    top: 14px;
    left: -14.5px;
    transform: translateY(-50%) rotate(270deg);
}

.tutorialCardTip.left-bottom {
    bottom: 14px;
    left: -14.5px;
    transform: translateY(-50%) rotate(270deg);
}

.tutorialCardTip.right-top {
    top: 14px;
    right: -14.5px;
    transform: translateY(-50%) rotate(90deg);
}

.tutorialCardTip.right-bottom {
    bottom: 14px;
    right: -14.5px;
    transform: translateY(-50%) rotate(90deg);
}

.tutorialCardTip.top-left {
    top: -13px;
    left: 6px
}

.tutorialCardTip.top-right {
    top: -13px;
    right: 6px
}

.tutorialCardTip.bottom-left {
    bottom: -13px;
    left: 6px;
    transform: rotate(180deg);
}

.tutorialCardTip.bottom-right {
    bottom: -13px;
    right: 6px;
    transform: rotate(180deg);
}

.tutorialCardTip.top {
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
}

.tutorialCardTip.bottom {
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
}

.tutorialCardTip.left {
    top: 50%;
    left: -14.5px;
    transform: translateY(-50%) rotate(270deg);
}

.tutorialCardTip.right {
    top: 50%;
    right: -14.5px;
    transform: translateY(-50%) rotate(90deg);
}