.page-layout {
    .page-layout-card {
        /* relative-col-width-(11|12); */
        // width: calc(var(--one-col) * 11 + var(--global-gap) * 11);
        width: calc(var(--one-col) * 12 + var(--global-gap) * 11);
    }
    .page-layout-content {
        /* relative-col-start-(1-5|2); */
        // margin-left: calc(var(--one-col) / 2 + var(--global-gap));
        margin-left: calc(var(--one-col) + var(--global-gap));
    }
}