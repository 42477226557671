@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Must be the same as in tailwind.config.js */
    :root {
        --color-brand700: #FF5C5C;
        --color-brand500: #FF7575;
        --color-prussian700: #0D222F;
        --color-prussian500: #273444;
        --color-prussian300: #3C4858;
        --color-prussian100: #A9B6C8;
        --color-grey700: #6D7681;
        --color-grey500: #C1CCDA;
        --color-grey300: #D3DCE6;
        --color-grey100: #DFE5ED;
        --color-grey50: #E5E9F2;
        --color-sunrise700: #F0B856;
        --color-sunrise500: #F4C674;
        --color-sunrise300: #F5D08E;
        --color-sunrise100: #F8DFB4;
        --color-blue700: #132E3E;
        --color-blue500: #1E3B4B;
        --color-blue300: #2B5C7B;
        --color-blue100: #457DA0;
        --color-teal700: #8ACED0;
        --color-teal500: #97DDDF;
        --color-teal300: #B6E0E2;
        --color-teal100: #C5E7E8;
        --color-green90: #007E4E;
        --color-green700: #00CC7E;
        --color-green500: #40DDA1;
        --color-green300: #D7FBD4;
        --color-orange700: #F6AE28;
        --color-orange500: #FEBB3F;
        --color-red700: #ED6964;
        --color-red500: #FD8884;
        --color-black700: #000000;
        --color-black500: #111111;
        --color-white700: #FFFFFF;
        --color-white500: #F9F9F9;
    }
}


code {
    font-family: var(--font-karla), monospace;
}

a {
    user-select: none;
}

a:not(.disabled):hover {
    cursor: pointer;
}

ul {
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 24px;
}

ol {
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 24px;
}

li {
    display: list-item;
    list-style-position: outside;
    margin: 0;
}

.flexCenterCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ltr {
    direction: ltr;
}

.rtl {
    direction: rtl;
}

.absolute-50 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* custom css */

.textArea.no-resize {
    resize: none;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 1030;
}

/* react-quill */
.quill .ql-toolbar {
	/* rounded-t-xs */
	border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.quill .ql-container {
	/* rounded-b-xs */
	border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}