.fab-trigger {
    box-shadow:
        /* First Shadow */
            0px 1px 3px -1px rgba(211, 220, 230, 0.35),
                /* Second Shadow */
            0px 3px 4px -1px rgba(211, 220, 230, 0.45),
                /* Third Shadow */
            0px 2px 3px -1px rgba(211, 220, 230, 0.65);
    border-radius: 100px;
}

.fab-action {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, 0);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}


.fab-action--visible {
    transform: translate(var(--x), var(--y));
    opacity: 1;
}
