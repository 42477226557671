@tailwind base;
@tailwind components;
@tailwind utilities;

/*
    Note: tailwind styles h1-h6 by default
    so we need to override them to set the font size. This means we have to add !important which also means that we can't change
    the fontSize anymore. On the other hand should these classes not be overwritten by individual stylings anyways. If you need text with a specific size, create a custom one
    See also: https://tailwindcss.com/docs/preflight

    How to add new fonts:
    - see page.tsx. Add the fonts to the upmost classNames
    - see tailwind.config.js.
        After doing this, you can use the fonts in the tailwind classes, e.g. className="font-karla"
    - see here. Add the fonts to the root element
*/

body, button, div, input, label, p, span, th, td {
    font-family: var(--font-karla), sans-serif;
}

@layer base {


    :root {
        --font-karla: var(--font-karla);
        --font-mont: var(--font-mont);
    }

    h1, h2, h3, h4, h5, h6, .text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6 {
        font-family: var(--font-mont), sans-serif;
        font-weight: 700 !important;
    }

    h1, .text-h1 {
        font-size: 64px !important;
        line-height: 130%;
        color: var(--color-prussian700);
    }

    h2, .text-h2 {
        font-size: 56px !important;
        line-height: 130%;
        color: var(--color-prussian700);
    }

    h3, .text-h3 {
        font-size: 48px !important;
        line-height: 130%;
        color: var(--color-prussian700);
    }

    h4, .text-h4 {
        font-size: 40px !important;
        line-height: 130%;
        color: var(--color-prussian700);
    }

    h5, .text-h5 {
        font-size: 32px !important;
        line-height: 130%;
        color: var(--color-prussian500);
    }

    h6, .text-h6 {
        font-size: 24px !important;
        line-height: 130%;
        color: var(--color-prussian500);
    }

    p, span {
        font-family: var(--font-karla), sans-serif;
        font-size: 16px;
        line-height: 130%;
        font-weight: 400;
        color: var(--color-prussian300);
    }

    a {
    }

    th, td {
        color: var(--color-prussian300);
    }

    .text-body1,
    .text-body1 > * {
        font-family: var(--font-karla), sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
    }

    .text-body {
        font-family: var(--font-karla), sans-serif;
        font-size: 16px;
        line-height: 130%;
        font-weight: 400;
        color: var(--color-prussian300);
    }

    .text-body-bold {
        font-family: var(--font-karla), sans-serif;
        font-size: 16px;
        line-height: 130%;
        font-weight: 600;
        color: var(--color-prussian300);
    }


    .text-body2 {
        font-size: 14px;
        line-height: 130%;
        font-family: var(--font-karla), sans-serif;
        font-weight: 400;
    }

    .text-body2-bold {
        font-size: 14px;
        line-height: 130%;
        font-family: var(--font-karla), sans-serif;
        font-weight: 600;
    }

    .text-caption {
        font-size: 12px;
        line-height: 130%;
        font-family: var(--font-karla), sans-serif;
        font-weight: 400;
        color: var(--color-prussian100);
    }
}
