.tooltipInline {
    position: relative;
    display: inline-block;
}

.tooltipInline .tooltiptext {
    visibility: hidden;
    user-select: none;
    pointer-events: none;
    position: absolute;
    word-break: break-word;
    z-index: 10;
}

.tooltipInline .left {
    top: 50%;
    left: 0;
    transform: translate(calc(-100% - 12px), -50%);
}

.tooltipInline .right {
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 12px), -50%);
}

.tooltipInline .top {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 12px));
}

.tooltipInline .bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 12px));
}


.tooltipInline.hover .tooltiptext {
    visibility: visible;
}

.tooltipInline .tooltiptext::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
}

/* Arrow for left positioned tooltip */
.tooltipInline .left.tip::after {
    top: 50%;
    right: -8px; /* Adjust the size of the arrow */
    margin-top: -8px;
    border-width: 8px 0 8px 10px;
    border-color: transparent transparent transparent var(--color-prussian100); /* Match tooltip background color */
}

/* Arrow for right positioned tooltip */
.tooltipInline .right.tip::after {
    top: 50%;
    left: -8px;
    margin-top: -8px;
    border-width: 8px 10px 8px 0;
    border-color: transparent var(--color-prussian100) transparent transparent;
}

/* Arrow for top positioned tooltip */
.tooltipInline .top.tip::after {
    bottom: -8px;
    left: 50%;
    margin-left: -8px;
    border-width: 10px 8px 0;
    border-color: var(--color-prussian100) transparent transparent transparent;
}

/* Arrow for bottom positioned tooltip */
.tooltipInline .bottom.tip::after {
    top: -8px;
    left: 50%;
    margin-left: -8px;
    border-width: 0 8px 10px;
    border-color: transparent transparent var(--color-prussian100) transparent;
}


.tooltipInline .top-left {
    top: 0;
    left: calc(50% + 14px);
    transform: translate(calc(-100%), calc(-100% - 12px));
}

.tooltipInline .top-right {
    top: 0;
    right: 14px;
    transform: translate(calc(100%), calc(-100% - 12px));
}

.tooltipInline .bottom-left {
    bottom: 0;
    left: calc(50% + 14px);
    transform: translate(calc(-100%), calc(100% + 12px));
}

.tooltipInline .bottom-right {
    bottom: 0;
    right: calc(50% + 14px);
    transform: translate(calc(100%), calc(100% + 12px));
}

/* Arrow for top-left positioned tooltip */
.tooltipInline .top-left.tip::after {
    bottom: -8px;
    right: 10px;
    border-width: 10px 8px 0;
    border-color: var(--color-prussian100) transparent transparent transparent;
}

/* Arrow for top-right positioned tooltip */
.tooltipInline .top-right.tip::after {
    bottom: -8px;
    left: 10px;
    border-width: 10px 8px 0;
    border-color: var(--color-prussian100) transparent transparent transparent;
}

/* Arrow for bottom-left positioned tooltip */
.tooltipInline .bottom-left.tip::after {
    top: -8px;
    right: 10px;
    border-width: 0 8px 10px;
    border-color: transparent transparent var(--color-prussian100) transparent;
}

/* Arrow for bottom-right positioned tooltip */
.tooltipInline .bottom-right.tip::after {
    top: -8px;
    left: 10px;
    border-width: 0 8px 10px;
    border-color: transparent transparent var(--color-prussian100) transparent;
}



