@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
}

body {
    background: #E5E9F2
}

.hide-required-stars .required {
    display: none;
}

.highlight-border {
    border: 1px solid #67f5cb !important;
}


.amchart-label {
    font-family: var(--font-mont), sans-serif;
    /* Add other styles if needed */
}