@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .fixed-col-width-1 {width: 104px;}
    .fixed-col-width-2 {width: 232px;}
    .fixed-col-width-3 {width: 360px;}
    .fixed-col-width-4 {width: 488px;}
    .fixed-col-width-5 {width: 616px;}
    .fixed-col-width-6 {width: 744px;}
    .fixed-col-width-7 {width: 872px;}
    .fixed-col-width-8 {width: 1000px;}
    .fixed-col-width-9 {width: 1128px;}
    .fixed-col-width-10 {width: 1256px;}
    .fixed-col-width-11 {width: 1384px;}
    .fixed-col-width-12 {width: 1512px;}


    /* Width calculations for each column span */
    .relative-col-width-1 {
        width: calc(var(--one-col));
    }

    .relative-col-width-1-5 {
        width: calc(var(--one-col) * 1.5 + var(--global-gap) * 0.5);
    }

    .relative-col-width-1-75 {
        width: calc(var(--one-col) * 1.75 + var(--global-gap) * 0.75);
    }

    .relative-col-width-2 {
        width: calc(var(--one-col) * 2 + var(--global-gap));
    }

    .relative-col-width-2-25 {
        width: calc(var(--one-col) * 2.25 + var(--global-gap) * 1.25);
    }

    .relative-col-width-2-4 {
        width: calc(var(--one-col) * 2.4 + var(--global-gap) * 1.4);
    }

    .relative-col-width-2-5 {
        width: calc(var(--one-col) * 2.5 + var(--global-gap) * 1.5);
    }

    .relative-col-width-2-75 {
        width: calc(var(--one-col) * 2.75 + var(--global-gap) * 1.75);
    }

    .relative-col-width-2-5-no-gap {
        width: calc(var(--one-col) * 2.5);
    }

    .relative-col-width-3 {
        width: calc(var(--one-col) * 3 + var(--global-gap) * 2);
    }

    .relative-col-width-4 {
        width: calc(var(--one-col) * 4 + var(--global-gap) * 3);
    }

    .relative-col-width-5 {
        width: calc(var(--one-col) * 5 + var(--global-gap) * 4);
    }

    .relative-col-width-6 {
        width: calc(var(--one-col) * 6 + var(--global-gap) * 5);
    }

    .relative-col-width-7 {
        width: calc(var(--one-col) * 7 + var(--global-gap) * 6);
    }

    .relative-col-width-8 {
        width: calc(var(--one-col) * 8 + var(--global-gap) * 7);
    }

    .relative-col-width-9 {
        width: calc(var(--one-col) * 9 + var(--global-gap) * 8);
    }

    .relative-col-width-10 {
        width: calc(var(--one-col) * 10 + var(--global-gap) * 9);
    }

    .relative-col-width-11 {
        width: calc(var(--one-col) * 11 + var(--global-gap) * 10);
    }

    .relative-col-width-12 {
        width: calc(var(--one-col) * 12 + var(--global-gap) * 11);
    }

    /* Width calculations for each column span (plus gap for tables) */
    .relative-col-width-1-plus-gap {
        width: calc(var(--one-col)) + var(--global-gap);
    }

    .relative-col-width-1-5-plus-gap {
        width: calc(var(--one-col) * 1.5 + var(--global-gap) * 1.5);
    }

    .relative-col-width-2-plus-gap {
        width: calc(var(--one-col) * 2 + var(--global-gap) * 2);
    }

    .relative-col-width-2-5-plus-gap {
        width: calc(var(--one-col) * 2.5 + var(--global-gap) * 2.5);
    }

    .relative-col-width-3-plus-gap {
        width: calc(var(--one-col) * 3 + var(--global-gap) * 3);
    }

    .relative-col-width-4-plus-gap {
        width: calc(var(--one-col) * 4 + var(--global-gap) * 4);
    }

    .relative-col-width-5-plus-gap {
        width: calc(var(--one-col) * 5 + var(--global-gap) * 5);
    }

    .relative-col-width-6-plus-gap {
        width: calc(var(--one-col) * 6 + var(--global-gap) * 6);
    }

    .relative-col-width-7-plus-gap {
        width: calc(var(--one-col) * 7 + var(--global-gap) * 7);
    }

    .relative-col-width-8-plus-gap {
        width: calc(var(--one-col) * 8 + var(--global-gap) * 8);
    }

    .relative-col-width-9-plus-gap {
        width: calc(var(--one-col) * 9 + var(--global-gap) * 9);
    }

    .relative-col-width-10-plus-gap {
        width: calc(var(--one-col) * 10 + var(--global-gap) * 10);
    }

    .relative-col-width-11-plus-gap {
        width: calc(var(--one-col) * 11 + var(--global-gap) * 11);
    }

    .relative-col-width-12-plus-gap {
        width: calc(var(--one-col) * 12 + var(--global-gap) * 11);
    }

    /* Width calculations as height for each column span */
    .relative-col-height-1 {
        height: calc(var(--one-col));
    }

    .relative-col-height-2 {
        height: calc(var(--one-col) * 2 + var(--global-gap));
    }

    .relative-col-height-3 {
        height: calc(var(--one-col) * 3 + var(--global-gap) * 2);
    }

    .relative-col-height-4 {
        height: calc(var(--one-col) * 4 + var(--global-gap) * 3);
    }

    .relative-col-height-5 {
        height: calc(var(--one-col) * 5 + var(--global-gap) * 4);
    }

    .relative-col-height-6 {
        height: calc(var(--one-col) * 6 + var(--global-gap) * 5);
    }

    .relative-col-height-7 {
        height: calc(var(--one-col) * 7 + var(--global-gap) * 6);
    }

    .relative-col-height-8 {
        height: calc(var(--one-col) * 8 + var(--global-gap) * 7);
    }

    .relative-col-height-9 {
        height: calc(var(--one-col) * 9 + var(--global-gap) * 8);
    }

    .relative-col-height-10 {
        height: calc(var(--one-col) * 10 + var(--global-gap) * 9);
    }

    .relative-col-height-11 {
        height: calc(var(--one-col) * 11 + var(--global-gap) * 10);
    }

    .relative-col-height-12 {
        height: calc(var(--one-col) * 12 + var(--global-gap) * 11);
    }

    /* No margin for start 1 */
    .relative-col-start-1 {
        margin-left: 0;
    }

    .relative-col-start-1-5 {
        margin-left: calc(var(--one-col) / 2 + var(--global-gap));
    }

    .relative-col-start-2 {
        margin-left: calc(var(--one-col) + var(--global-gap));
    }

    .relative-col-start-3 {
        margin-left: calc(var(--one-col) * 2 + var(--global-gap) * 2);
    }

    .relative-col-start-4 {
        margin-left: calc(var(--one-col) * 3 + var(--global-gap) * 3);
    }

    .relative-col-start-5 {
        margin-left: calc(var(--one-col) * 4 + var(--global-gap) * 4);
    }

    .relative-col-start-6 {
        margin-left: calc(var(--one-col) * 5 + var(--global-gap) * 5);
    }

    .relative-col-start-7 {
        margin-left: calc(var(--one-col) * 6 + var(--global-gap) * 6);
    }

    .relative-col-start-8 {
        margin-left: calc(var(--one-col) * 7 + var(--global-gap) * 7);
    }

    .relative-col-start-9 {
        margin-left: calc(var(--one-col) * 8 + var(--global-gap) * 8);
    }

    .relative-col-start-10 {
        margin-left: calc(var(--one-col) * 9 + var(--global-gap) * 9);
    }

    .relative-col-start-11 {
        margin-left: calc(var(--one-col) * 10 + var(--global-gap) * 10);
    }

    .relative-col-start-12 {
        margin-left: calc(var(--one-col) * 11 + var(--global-gap) * 11);
    }
}

@layer base {
    :root {
        --side-bar-width: 280px;
        --top-bar-height: 64px;

        --max-width: 4000px;

        /*--global-width: min(calc(100vw), var(--max-width));*/

        --global-gap: 24px;
    }
}

.sideBar {
    width: var(--side-bar-width);
}

/* see TopBar.js for explanation */
.topBar-cont {
    margin-top: calc(var(--top-bar-height) * -1);
}

.topBar-height {
    height: var(--top-bar-height);
}

/* Move everything if the sideBar is active */
/* We can't set margin-left on the globalGrid itself and therefore need a container that moves everything */
.hasSideBar.globalGridContainer {
    margin-left: var(--side-bar-width);
}

.globalGrid {
    position: relative;
    width: var(--global-width);
    --global-gap: calc(var(--global-width) / 63);
    --global-width: 1512px;
    --one-col: calc((var(--global-width) - (11 * var(--global-gap))) / 12); /* Width of one column */
}

/* switch to 6 columns on mobile */
/*.bp-mobile .globalGridSideBar {*/
/*    --one-col: calc((var(--global-width) - (5 * var(--global-gap))) / 6); !* Width of one column *!*/
/*}*/

/* Apply right margin for gaps */
/*
[class^="relative-col-width"]:not(:last-child) {
    margin-right: var(--global-gap);
}
*/

.grid-helper-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--global-width);
    display: grid;
    z-index: 1000;
    grid-template-columns: calc(var(--one-col) / 2) var(--global-gap) repeat(11, var(--one-col) var(--global-gap)) calc(var(--one-col) / 2); /* Alternating columns and gaps */
    pointer-events: none; /* Allows clicks to pass through */
}

    .grid-helper-overlay {
        grid-template-columns: repeat(11, var(--one-col) var(--global-gap)) var(--one-col); /* Alternating columns and gaps */
    }

/* switch to 6 columns on mobile */
.bp-mobile .grid-helper-overlay {
    grid-template-columns: repeat(5, var(--one-col) var(--global-gap)) var(--one-col); /* Alternating columns and gaps */
}

/* Style for columns in the helper */
.grid-helper-column {
    /*background-color: rgba(0, 0, 255, 0.2); !* Semi-transparent blue for columns *!*/
    background-color: rgba(0, 0, 255, .05); /* Semi-transparent blue for columns */
}

/* Style for gaps in the helper */
.grid-helper-gap {
    width: var(--global-gap); /* Gap width */
    /*background-color: rgba(255, 0, 0, 0.2); !* Semi-transparent red for gaps *!*/
    background-color: rgba(255, 0, 0, .05); /* Semi-transparent red for gaps */
}